import { SearchIH } from '.'

export type VerticalType =
    | 'Poder'
    | 'Tributos'
    | 'Saúde'
    | 'Energia'
    | 'Info'
    | 'Trabalhista'
    | 'Alerta'

export enum VerticalName {
    PODER = 'Poder',
    TRIBUTOS = 'Tributos',
    SAUDE = 'Saúde',
    ENERGIA = 'Energia',
    TRABALHISTA = 'Trabalhista',
    INFO = 'Info',
    ALERTA = 'Alerta'
}

export type VerticalNameActive = Omit<VerticalName, 'ENERGIA' | 'ALERTA'>

export type HasPROProfile = {
    has_poder: boolean
    has_tributos: boolean
    has_saude: boolean
    has_trabalhista: boolean
}

export const permissionMap: Record<string, keyof HasPROProfile> = {
    [VerticalName.PODER.toLowerCase()]: 'has_poder',
    [VerticalName.TRIBUTOS.toLowerCase()]: 'has_tributos',
    [VerticalName.SAUDE.toLowerCase()]: 'has_saude',
    [VerticalName.TRABALHISTA.toLowerCase()]: 'has_trabalhista'
}

export type ModalI = {
    isOpen: boolean
    closeModal: () => void
    vertical: VerticalType
    bulletItems: BulletItensI
}

export type BulletItensI = {
    Poder: string[]
    Tributos: string[]
    Saúde: string[]
    Trabalhista: string[]
    Energia: string[]
    Info: string[]
    Alerta: string[]
}

export type FormRDI = {
    url: string
    type: VerticalType | 'Alerta'
}

export type dataLayerI = {
    event: string
    [key: string]: any
}

export type TypeMetadataError = {
    category: {
        icon: string
        id: string
        name: string
        vertical: {
            id: string
            name: string
        }
    }
    vertical: {
        id: string
        name: string
    }
    detail: string
    post: {
        id: string
        title: string
    }
}

export interface TypeMetadataSuccess {
    id: number
    category: Category
    title: string
    plain_content: string
    send_time: string
    html_url: string
    tags: any[]
    authors: any[]
    breadcrumbs: Breadcrumbs
}
interface Breadcrumbs {
    id: number
    title: string
    slug: string
    children: Children
}
interface Children {
    id: number
    title: string
    slug: string
}
interface Category {
    id: number
    name: string
    vertical: Vertical
    icon: string
}
interface Vertical {
    id: number
    name: string
}

export type WidgetDataType = {
    userStatus: any
    auth: any
    showMessage: boolean
    isHovering: boolean
    setShowMessage: any
    hoverRef: any
    nl: NewsletterI[]
    isPRO: boolean
}

export type verticalType = 'poder' | 'tributos' | 'saude' | 'trabalhista'
export type TypeData = verticalType | 'all'

export type NewsletterI = {
    title: string
    link: string
    type: TypeData
    date: Date
    hat: string
    icon: string
    verticalSlug: string
    newsletterSlug: string
}

export type ProContentBO = {
    authors: any[]
    icon: string
    id: number
    send_time: string
    slug: string
    title: string
    category: {
        icon: string
        id: number
        name: string
        vertical: {
            name: string
            id: number
        }
    }
}

export type CardTypeProps = {
    content: SearchIH
    index: number
    searchTerm: string | undefined
}

type AuthorI = {
    name: string
    url: string
}

export type InfoContentI = {
    title: string
    photo: string
    hat: string
    preview: string
    date: string
    dateFriendly: string
    link: string
    author: AuthorI[]
    type: 'info'
}

export type ProContentI = {
    title: string
    date: string
    dateFriendly: string
    category: string
    vertical: string
    link: string
    icon: string
    type: 'pro'
}

export const API_COBERTURAS = process.env.NEXT_PUBLIC_API_COBERTURAS_CATEGORIA

export interface LogoItemCoberturasEspeciaisChild {
    logo: string
    name: string
}

export interface MultipleLogosCoberturasEspeciaisChild {
    isActive: string
    master: LogoItemCoberturasEspeciaisChild[]
    normal: LogoItemCoberturasEspeciaisChild[]
    apoio: LogoItemCoberturasEspeciaisChild[]
}

export interface HeaderCoberturasEspeciaisChild {
    id: number
    name: string
    slug: string
    link: string
    relation_type: string
    company_name: string
    description: string
    disclaimer: string
    company_logo: string
    background_image: string
    multiple: MultipleLogosCoberturasEspeciaisChild
}

interface AuthorCoberturasEspeciaisChild {
    name: string
    url: string
    display_name: string
    slug: string
}

export interface PostsCoberturasEspeciaisChild {
    id: number
    title: string
    link: string
    excerpt: string
    thumbnail: string
    authors: AuthorCoberturasEspeciaisChild[]
    category: string
    category_id: number
    date: string
    hat: string
}

export interface ContentCoberturasEspeciaisChild {
    posts: PostsCoberturasEspeciaisChild[]
    total: number
    total_page: number
    page: number
    per_page: number
}

export interface ApiResponseCoberturasEspeciais {
    header: HeaderCoberturasEspeciaisChild
    content: ContentCoberturasEspeciaisChild
}
